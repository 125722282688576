import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './useCases/reportWebVitals';
import Home from "./pages/home";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { Provider } from 'react-redux';
import store from './store'
import Economy from "./pages/economy";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";

library.add(fas, far)
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
const queryClient = new QueryClient()
root.render(
    <Provider store={store}>
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <App />
        </QueryClientProvider>
    </React.StrictMode>
       </Provider>
);
function App() {
    return (<Router>
        <Routes>
            <Route path="/economy" element={<Economy />}/>
            <Route path="/" element={<Home />}/>
        </Routes>
</Router>)
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
