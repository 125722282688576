import React, {useEffect} from 'react';
import './index.scss';
import Footer from "../../components/footer";
import Header from "../../components/header";
import {useAppSelector, useAppDispatch} from '../../store/hook'
import MenuItem from '@mui/material/MenuItem';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import EconomyTable from "../../components/EconomyTable";
import Country from "../../core/country";
import {setCountries} from "../../store/country";

const Economy = () => {
    const countryHover = useAppSelector((state) => state.country.hover)
    const countries = useAppSelector((state) => state.country.countries)
    const [activeCountry, setActiveCountry] = React.useState<Country | null>(null);
    const [activeType, setActiveType] = React.useState<'import' | 'export' | 'total'>('import');
    const [activeData, setActiveData] = React.useState<any>(null);
    const dispatch = useAppDispatch()

    useEffect(() => {
        fetchData()
    }, [activeType,activeCountry ])

    useEffect(() => {
        window.scrollTo(0, 0);
        if(!countries || countries.length === 0){
            const fetchDataCountries = async () => {
                // get the data from the api
                const data = await fetch('https://think-africa-back.herokuapp.com/countries')
                // convert data to json
                const json = await data.json()
                dispatch(setCountries(json as unknown as Country[]))
                if(!activeCountry){
                    setActiveCountry(json[7])
                }

            }
            fetchDataCountries()
        }else {
            if(!activeCountry){
                setActiveCountry(countries[7])
            }
        }
    }, [])

    const fetchData = async () => {
        // get the data from the api
        const data = await fetch(`https://think-africa-back.herokuapp.com/countries/economy/${ activeCountry?.code}/${activeType}/2021`)
        // convert data to json
        const json = await data.json()
        setActiveData(json)
    }


    const changeActiveCountry = (event: SelectChangeEvent) => {
        const target = countries.find(country => country.id === event.target.value)
        if (target) {
            setActiveCountry(target)
        }
    };
    const changeActiveType = (type: 'import' | 'export' | 'total') => {
        setActiveType(type)
    };
    return (<>
            <Header/>
            <div className={"economy"}>
                <div className={"economy-container"}>

                    <div className="economy-category">
                        <div>
                            <div>Retrouvez l'économie en détails de chaque pays.</div>
                        </div>

                        <div>
                            <button>Par Pays</button>
                            <button disabled>Par Resources</button>
                        </div>
                    </div>
                    <div className="economy-country-container">
                        <div className="economy-country">
                            {activeCountry && <><img src={activeCountry?.flag} alt={"flag"}/> <div><h3>{activeCountry?.name}</h3> <p>{activeCountry?.capital}</p></div></>}
                        </div>
                        <Select
                            displayEmpty
                            value={activeCountry?.id}
                            renderValue={(selected) => {
                                if (!selected) {
                                    return <span>Sélectionner un pays</span>;
                                }
                                const target = countries.find(country => country.id === selected)
                                // @ts-ignore
                                return target?.name;
                            }}
                            className={"economy-tab-select"}
                            inputProps={{'aria-label': 'Without label'}}
                            onChange={changeActiveCountry}
                        >
                            {countries.map((country) => (
                                <MenuItem
                                    key={country.name}
                                    value={country.id}
                                >
                                    {country.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>

                    <div className="economy-tab">
                        <div className="economy-tab-type">
                            <h2>Exportation & importation</h2>
                            <div>
                                <button onClick={() => changeActiveType('import')}
                                        className={activeType === 'import' ? 'economy-tab-type-active' : ''}>Importation
                                </button>
                                <button onClick={() => changeActiveType('export')}
                                        className={activeType === 'export' ? 'economy-tab-type-active' : ''}>Exportation
                                </button>
                                <button onClick={() => changeActiveType('total')}
                                        className={activeType === 'total' ? 'economy-tab-type-active' : ''}>Total
                                </button>
                            </div>
                        </div>
                        <div>
                            {activeData && <EconomyTable data={activeData}/>}
                        </div>
                    </div>
                    <div className="economy-graph">
                        <div  className="economy-graph-soon">
                                <div className={"economy-graph-soon-text"}>
                                    <h3>Evolution économique <span>Bientôt disponible</span></h3>
                                    <p>Retrouvez dans cette section l'évolution économique du pays pour chaque ressource</p>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}

export default Economy;
