import React from 'react';
import './index.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {Link, LinkProps, useResolvedPath} from "react-router-dom";
import {IconName} from "@fortawesome/fontawesome-svg-core";
import {
    useMatch
} from "react-router-dom";
const Header = () => {
    return (
        <div className="header-container">
            <div className="header">
                <div className="header-logo-container">
                    <div className={"header-logo"}>
                        <img src={"logo.png"} alt="logo"/>
                    </div>
                    <h1>
                        Mungano
                    </h1>
                </div>
                <div className="header-links">
                    <CustomLink to={'/'} title={'Carte'}>   <FontAwesomeIcon icon={"fa-solid fa-earth-africa" as IconName}/>Carte </CustomLink>
                    <CustomLink to={'/economy'} title={'Carte'}  >   <FontAwesomeIcon icon={"fa-solid fa-layer-group" as IconName}/>Economie </CustomLink>
                </div>
                <div className="header-btn">
                </div>{/**
             <div className="header-btn-mobile">
             <button><FontAwesomeIcon  size={'xl'} icon={faBars}/></button>
             </div>
             **/}

            </div>
        </div>
    );
}

export default Header;

function CustomLink({ children, to, ...props  }: LinkProps) {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });

    return (
            <Link   className={  match ? " header-links-active " : "" }  to={to}>    {children} </Link>
    );
}