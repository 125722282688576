import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './index'
import Country from "../core/country";

interface CountryState {
    hover: Country | null,
    countries: Country[]
    active:  Country | null,
}

const initialState: CountryState = {
    hover: null,
    active: null,
    countries: []
}

export const countrySlice = createSlice({
    name: 'country',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setHoverCountry: (state, action: PayloadAction<Country> ) => {
            state.hover = action.payload
        },
        setCountries: (state, action: PayloadAction<Country[]> ) => {
            state.countries = [...action.payload]
        },
        setActiveCountry: (state, action: PayloadAction<Country | null> ) => {
            state.active = action.payload
        },
    },
})

export const { setHoverCountry, setCountries, setActiveCountry } = countrySlice.actions

export default countrySlice.reducer