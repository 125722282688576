import React from 'react';
import './index.scss';
import {closeAfricaMap} from "../../store/africaMap";
import {setActiveCountry} from "../../store/country";
import {useAppDispatch} from "../../store/hook";
import { useNavigate } from "react-router-dom";


const EconomyCountryExchange = (data: {
    products: Array<{ value: number; name: string; img: string }>,
    title: string
}) => {
    const {products} = data
    const [hover, setHover] = React.useState(false);
    const dispatch = useAppDispatch()
    const navigate = useNavigate();

    const handleRedirect= async () =>{
        dispatch(closeAfricaMap())
        dispatch(setActiveCountry(null))
        navigate("/economy");
    }
    return (
        <div className="country-right-eco-section-card" onClick={() => handleRedirect() } onMouseEnter={() => setHover(true) } onMouseLeave={() => setHover(false) }>
                <h3>{data.title}</h3>
                <div className="country-right-eco-section-card-row">
                    {products?.map(product => {
                        return (
                            <div className="country-right-eco-section-card-row-obj" key={product.name}>
                                <img src={product.img} alt={product.name}/>
                                <p> {product.name}</p></div>)
                    })}
                </div>
                {hover && <div className="country-right-eco-section-card-pro">
                    <div>Les détails de ces données sont accessibles <br/>pour les membres Pro</div>
                    <button>En savoir plus</button>
                </div>}
        </div>
    );
}

export default EconomyCountryExchange;
