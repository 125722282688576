import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './index'
import Country from "../core/country";

interface AfricaMapState {
    open: boolean,
}

const initialState: AfricaMapState = {
    open: false,
}

export const mapSlice = createSlice({
    name: 'map',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        openAfricaMap: (state ) => {
            state.open = true
        },
        closeAfricaMap: (state ) => {
            state.open = false
        },
    },
})

export const { closeAfricaMap,openAfricaMap } = mapSlice.actions

export default mapSlice.reducer