// Footer.tsx
import React from 'react';
import './index.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTwitter, faFacebook, faInstagram} from '@fortawesome/free-brands-svg-icons'

type LinkProps = {
    label: string;
    url: string;
    blank?: boolean;
};

const FooterLink: React.FC<LinkProps> = ({label, url, blank}) => (
    <li><a href={url} target={blank ? `"_blank"` : ''} >{label}</a></li>
);

const Footer: React.FC = () => (
    <footer className="footer">
        <div className="footer-container">
            <div className="footer-section">
                <div className={"footer-logo"}>
                    <h3>
                        Mungano
                    </h3>
                </div>
                <p> La plateforme pour se connecter à l'Afrique</p>
            </div>
            <div className="footer-section">
                <h3>Explorer</h3>
                <ul>
                    <FooterLink label="Economie" url="/economy"/>
                </ul>
            </div>
            <div className="footer-section">
                <h3>À propos</h3>
                <ul>
                    <FooterLink label="Pourquoi Mungano ?" url="https://www.instagram.com/mungano.co" blank={true}/>
                    <FooterLink label="contact@mungano.co" url="#"/>
                </ul>
            </div>
            <div className="footer-section social-media">
                <a href="https://www.facebook.com/profile.php?id=100094474383430"  target="_blank"><FontAwesomeIcon size={'lg'} icon={faFacebook}/></a>
                <a href="https://twitter.com/munganoco" target="_blank" ><FontAwesomeIcon size={'lg'} icon={faTwitter}/></a>
                <a href="https://www.instagram.com/mungano.co" target="_blank"><FontAwesomeIcon size={'lg'} icon={faInstagram}/></a>
            </div>
        </div>
        <div className="footer-btm">
            © 2023 Mungano - Tous droits réservés
        </div>
    </footer>
);

export default Footer;
