import React from 'react';
import './index.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconName} from "@fortawesome/fontawesome-svg-core";
var CurrencyFormat = require('react-currency-format');

const EconomyLineDetails = (data: { product: { fr: string; icon: string; value: number } }) => {
    const {product} = data
    return (
        <div className="eco-line-details" key={product.fr}>
            <div className="eco-line-details-icon">
                <FontAwesomeIcon icon={ (product.icon || "fa-regular fa-gem") as unknown as IconName}/>
            </div>
            <div  className="eco-line-details-txt">{product.fr}</div>
            <div  className="eco-line-details-value">
                <CurrencyFormat value={ parseInt((product.value / 1000000 )as unknown as string )} displayType={'text'} thousandSeparator={false} prefix={''} suffix={'M'} />
</div>
        </div>
    );
}

export default EconomyLineDetails;
