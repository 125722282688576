import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {TableVirtuoso, TableComponents} from 'react-virtuoso';
import {IconName} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './index.scss';

const CurrencyFormat = require('react-currency-format')

interface Data {
    id: string;
    value: number;
    resource: string;
    icon: string;
}

interface ColumnData {
    dataKey: keyof Data;
    label: string;
    numeric?: boolean;
    width: number;
}


const columns: ColumnData[] = [
    {
        width: 20,
        label: 'Rang',
        dataKey: 'id',
        numeric: true,
    },
    {
        width: 250,
        label: 'Ressource',
        dataKey: 'resource',

    },
    {
        width: 250,
        label: 'Montant en dollars US par an',
        dataKey: 'value',
        numeric: true,
    },
];


const VirtuosoTableComponents: TableComponents<Data> = {
    Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
        <TableContainer component={Paper} {...props} ref={ref}/>
    )),
    Table: (props) => (
        <Table {...props} sx={{borderCollapse: 'separate', tableLayout: 'fixed'}}/>
    ),
    TableHead,
    TableRow: ({item: _item, ...props}) => <TableRow {...props} />,
    TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
        <TableBody {...props} ref={ref}/>
    )),
};

function fixedHeaderContent() {
    return (
        <TableRow>
            {columns.map((column) => (
                <TableCell
                    key={column.dataKey}
                    variant="head"
                    align={column.numeric || false ? 'right' : 'left'}
                    style={{width: column.width}}
                    sx={{
                        backgroundColor: 'background.paper',
                    }}
                    className={"economy-table-header"}
                >
                    {column.label}
                </TableCell>
            ))}
        </TableRow>
    );
}

function rowContent(_index: number, row: Data) {
    return (
        <React.Fragment>
            {columns.map((column) => (
                <TableCell
                    key={column.dataKey}
                    align={column.numeric || false ? 'right' : 'left'}
                >
                    {column.dataKey === 'id' ? (_index + 1) : displayResource(row, column.dataKey)}

                </TableCell>
            ))}
        </React.Fragment>
    );
}

function displayResource(row: Data, key: keyof Data) {
    if (key === 'resource') {
        return <><FontAwesomeIcon icon={(row['icon'] || 'fa-solid fa-table-cells-large') as IconName}
                                  className={"economy-table-row-icon"}/>{row[key]}</>
    }
    if (key === 'value') {
        return  <CurrencyFormat className={"economy-table-row-value"}
            value={parseInt((row[key] / 1000000) as unknown as string)} displayType={'text'} thousandSeparator={false}
            prefix={''} suffix={'M'}/>
    }
    return (
        <>{row[key]}</>
    );
}

export default function EconomyTable(props: { data: Data[] }) {
    return (
        <Paper style={{height: 600, width: '100%'}}>
            <TableVirtuoso
                data={props.data}
                components={VirtuosoTableComponents}
                fixedHeaderContent={fixedHeaderContent}
                itemContent={rowContent}
            />
        </Paper>
    );
}