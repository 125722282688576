import React, {useEffect} from 'react';
import map from '../../map.svg';
import './index.scss';
import AfricaMap from "../../components/map/africa";
import Footer from "../../components/footer";
import Header from "../../components/header";
import {useAppSelector, useAppDispatch} from '../../store/hook'
import Country from "../../core/country";
import {setActiveCountry, setCountries} from "../../store/country";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpFromBracket, faEarthAfrica} from "@fortawesome/free-solid-svg-icons";
import SwipeableViews from 'react-swipeable-views';
import {autoPlay} from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const Home = () => {
    const countryHover = useAppSelector((state) => state.country.hover)
    const countries = useAppSelector((state) => state.country.countries)
    const dispatch = useAppDispatch()
    const openCountryDetails = (iso: string): void => {
        const countryData = countries?.find((country: any) => country.code === iso) as Country
        dispatch(setActiveCountry(countryData))
    }
    const [activeStep, setActiveStep] = React.useState(0);
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

    useEffect(() => {
        const fetchData = async () => {
            // get the data from the api
            const data = await fetch('https://think-africa-back.herokuapp.com/countries')
            // convert data to json
            const json = await data.json()
            dispatch(setCountries(json as unknown as Country[]))
        }
        fetchData()

    }, [])


    return (<>
            <Header/>
            <div className="map-container">
                <div className={"map-container-left"}>
                    <div className="map-info">
                        {!countryHover && <div className="map-info-top"><h2
                            className="map-info-top-title map-info-top-title-empty"> Sélectionner un pays pour
                            commencer </h2></div>}
                        {!countryHover &&
                            <div className="map-info-text map-info-text-empty"> Connectez-vous avec les entreprises
                                africaines et exploitez des ressources précises pour
                                chaque pays</div>}
                        {countryHover && <div className="map-info-top">
                            <div className="map-info-top-img"><img
                                src={countryHover?.flag}
                                alt={""}/></div>
                            <div>
                                <h2 className="map-info-top-title">{countryHover?.name}</h2>
                                <p>{countryHover?.capital}</p>
                            </div>
                        </div>}
                        {countryHover && <div className="map-info-text">
                            <h3> {countryHover?.motto}</h3>
                            {countryHover?.presentation}
                        </div>}
                        {countryHover && <div className="map-info-bottom">
                            <h3>Econonomie <span></span></h3>
                            <div className="map-info-bottom-eco"><h4><FontAwesomeIcon icon={faArrowUpFromBracket}/>Exportation
                            </h4>
                                <span>Rang parmi les pays africain</span>
                            </div>
                            {countryHover?.economy?.topExport?.map(row => {
                                return (<div className="map-info-bottom-rank">
                                    <div className={`map-info-bottom-rank-nb map-info-bottom-rank-nb-${row.rank}`}>
                                        <span>{row.rank}{row.rank === 1 ? 'er' : 'e'}</span>
                                    </div>
                                    <div className="map-info-bottom-rank-text">
                                        {row.fr}
                                    </div>
                                </div>)
                            }) || <div>


                            </div>}
                            <div className="map-info-bottom-eco"><h4><FontAwesomeIcon icon={faEarthAfrica}/>Pays avec le
                                plus d'échanges</h4>
                                <span>Exportation et importation</span>
                            </div>
                            <div className="country-right-eco-section-card-row">
                                {countryHover.economy.countryWithMostExchange.map(product => {
                                    return (
                                        <div className="country-right-eco-section-card-row-obj" key={product.name}><img
                                            src={product.img} alt={product.name}/><p> {product.name}</p></div>)
                                })}
                            </div>
                        </div>}
                    </div>
                </div>
                <div className="map-container-mobile">
                    <h2>Mungano vous connecte à l'Afrique</h2>
                    <p className="map-mobile-desc">Commencer par séletionner un pays en utilisant la liste déroulante ou cliquant de dessus sur la carte</p>
                    <div className="map-country-cards">
                        <div
                            className="map-country-cards-slide"
                        >
                                {countries && countries.map(country => {
                                    return (
                                        <div className="map-country-cards-slide-img"  key={country.id}  onClick={() => openCountryDetails(country.code)}>
                                            <img src={country.flag} />
                                            <div>
                                                <h2 className="map-info-top-title">{country?.name}</h2>
                                                <p>{country?.capital}</p>
                                            </div>
                                        </div>

                               )})}
                        </div>
                    </div>
                </div>
                <AfricaMap/>
            </div>
            <Footer/>
        </>
    );
}

export default Home;
